import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"

import BlogPost from "../../../../components/BlogPost"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Steppjacken"
      category="Wintertrends 2019/2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <Typography paragraph={true}>
            Auch diesen Winter sind die Steppjacken in Mode. Steppjacken kann es in verschiedenen
            Längen geben, wie beispielweise den Steppmantel. Steppjacken gibt es mit Kaputzen, die
            teilweise abnehmbar sind. Steppjacken kann man entweder beim Sport oder auch in der
            Freizeit, zum Beispiel in der Stadt beim Bummeln tragen. Steppmäntel sind hingegen zu
            Steppjacken sehr klassisch und edel. Diesen Winter ist weiß im Trend und daher ist eine
            weiße Steppjacke der Hit. Wer mehr auf knallige Farben steht, der wird mit einer roten
            Steppjacke alle Blicke auf sich ziehen. Jedenfalls ist für jede Frau eine passende
            Steppjacke für den Winter dabei!
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <SearchResults
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24609189319&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33406636?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "TOM TAILOR Steppjacke ",
                price: 89.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608474979&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/32527458?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Superdry Steppjacke",
                price: 119.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24704541427&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/32769919?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "CMP Steppjacke",
                price: 99.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24647316025&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35299497?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "G-Star RAW Steppjacke ",
                price: 199.95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24607796007&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/15089037?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Aniston CASUAL Steppjacke",
                price: 129.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608751555&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33710090?w=1186&h=1694",
                    title: "",
                  },
                ],
                name: "Laurèl Steppjacke",
                price: 279,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=24609189911&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/32940751?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Fuchs Schmitt Steppjacke ",
                price: 289.99,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=24994735533&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35227416?w=1060&h=1500",
                    title: "",
                  },
                ],
                name: "s.Oliver Steppmantel",
                price: 139.99,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
